import React from "react";
import axios from "axios";
import { generateRequestConfig } from "./constants";
import { ErrorAction, CsrfState } from "./interfaces";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';


export const fetchCsrfToken = async (
  setState: React.Dispatch<React.SetStateAction<CsrfState>>  
 
) => {

  try {
    const requestConfigCSRF = generateRequestConfig(
      "get", 
      "get_csrf_token", 
      {}, 
      { 
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Do-Not-Translate": 'en',
       },
    );
    const response = await axios(requestConfigCSRF);
      setState((prevState) => ({
        ...prevState,
        csrf_token: response.data.csrf_token,
      }));
  } catch (error) {
    handleErrorForms(error);
  }
 
};

interface appInfo {
  action: string;
  name: string;
}

export const getappInfo = async (setJsonData: (data: appInfo) => void) => {
  const app_id = getIdApp();
  const ref = referer();

  try {
    
    const requestappInfo = generateRequestConfig(
      "get",
      "apps",
      {},
      { 
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Do-Not-Translate": 'en',
       },
      {
        action: "app_id",
        app_id: app_id,
      }
    );
    
    const response = await axios(requestappInfo);
        setJsonData(response.data.data);
    
      if(!ref && response.data.data.url_callback){
        Cookies.set('referer', response.data.data.url_callback , { expires: 7 });
      }

  } catch (error) {
    handleErrorForms(error);
  }
};


export function handleErrorForms(error: any): ErrorAction | null {
  try {
    const { response } = error;

    if (!response) {
      return error.message ? { message: error.message } : null;
    }
    
    const { status, data } = response;
   

    const errorFields = ["email", "password", "passwordRepeat", "code"] as const;

    for (const field of errorFields) {
      if (data.errors?.[field]) {
        return { message: data.errors[field][0], field };
      }
    }

    switch (status) {
      case 301:
        return { message: data.message, "status": status, redirect: data.redirect };

      case 401:
        return { message: data.message, "status": status };
      
      case 500:
        return { message: data.message, "status": status };

      default:
        return null;
    }
    
   //return data.message ? { message: data.message } : null;

  } catch {
    return { message: "Something went wrong" };
  }
}


export const acessToken = () => {
  const access_token = Cookies.get("x-auth-token");
  if(access_token)
    return access_token;
  else return null;
};

export const getDeviceId = () => {
  let device_id = Cookies.get('device_id')
  
  if(!device_id ){
    device_id = uuidv4()
    Cookies.set('device_id', uuidv4(), { expires: 365 });
    return device_id
  }else{
      
    return device_id;
  }

}

export const getIdApp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let referer = urlParams.get("app_id");
  let refererCookie = Cookies.get('app_id')
  
  if(referer && referer!='null' ){
    Cookies.set('app_id', referer, { expires: 7 });
  }else{
     if(refererCookie){
      referer = refererCookie;
     }
  }
  if(referer)
    return referer;
  else 
    return null;
};

export const referer = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let referer = urlParams.get("referer");
  let refererCookie = Cookies.get('referer')
  
  if(referer && referer!='null' ){
    Cookies.set('referer', referer, { expires: 7 });
  }else{
     if(refererCookie){
      referer = refererCookie;
     }
  }
  if(referer)
    return referer;
  else 
    return null;
};


export const getEmailFromParams = (): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");

  // Decode the URL-encoded email parameter
  const decodedEmail = email ? decodeURIComponent(email) : null;

  // Validate the decoded email using a regular expression
  if (decodedEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(decodedEmail)) {
    return decodedEmail;
  }
  return undefined;
};

 