import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import FormItem from "../../components/FormItem";
import InputField from "../../components/InputField";
import { getappInfo, handleErrorForms, getIdApp, getDeviceId} from "../../utils";
import Button from "../../components/Button";
import InputErrorMessage from "../../components/InputErrorMessage";
import { SubmitHandler, useForm } from "react-hook-form";
import { generateRequestConfig } from "../../constants";
import {AuthContext} from "../../contexts/AuthContext";
import { loginFields, getEmailErrorMessages, getPasswordErrorMessages, LoginFormDataType } from "./Login.constants";
import LoginSkeleton from '../Skeletons/LoginSkeleton'
import useTimer from "../../hooks/useTimer";

type LoginState = {
  generalErrors: string | undefined;
  submitButton: boolean;
  appInfo: any;
};

const Login: React.FC = () => {
  type FormField = "email" | "password" ;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const { handleLogIn, AuthContextState, redirectToApp } = useContext(AuthContext);
  const { csrf_token, idApp, referer } = AuthContextState;
  
  const [state, setState] = useState<LoginState>({
    appInfo: null,
    generalErrors: undefined,
    submitButton: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<LoginFormDataType>();

  const isLoaded = useTimer(500);

  useEffect(() => {
    redirectToApp();
    document.title = t("Login");
    
    if (!getIdApp()) {
      navigate('/');
    }

    if(!appInfo)
    {
      getappInfo((data) => setState(prevState => ({ ...prevState, appInfo: data })));
    }

  }, [t, i18n]);
  
  const handleSubmitLoginForm: SubmitHandler<LoginFormDataType> = async (data) => {
    setState((prevState) => ({ ...prevState, submitButton: true }));
    setState((prevState) => ({ ...prevState, generalErrors: undefined }));

    const requestConfigLogin = generateRequestConfig("post", "login", {
      ...data,
       app_id: idApp,
       device_id: getDeviceId(),
    }, {
      "X-CSRF-TOKEN": csrf_token,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Accept-Language": currentLang,
      "Do-Not-Translate": 'en',
    });
     
    try {
      const res = await axios(requestConfigLogin);
      if(res.data.redirect=='confirmation') {
        const encodedEmail = encodeURIComponent(data.email);
        navigate(
          `/${currentLang}/activation/?app_id=${idApp}&referer=${referer}&email=${encodedEmail}`
        );
      }else{
        handleLogIn(res.data);
      }
    } catch (error) {

      const errorAction = handleErrorForms(error);
      if (errorAction) {
        const { message, field } = errorAction;
        if (field) {
          setError(field as FormField, {
            type: 'server',
            message: message,
          });
        } else {
          setState((prevState) => ({ ...prevState, generalErrors: message }));
        }
      }
      setState((prevState) => ({ ...prevState, submitButton: false }));
    }
  };
  
  const emailErrorMessages = getEmailErrorMessages(errors);
  const passwordErrorMessages = getPasswordErrorMessages(errors);
  const { appInfo, generalErrors, submitButton } = state;

  return (
    <>
      {!isLoaded ? <LoginSkeleton /> : (
        <>
          <h1 className="text-center auth-title-form">
            {t("Login to")}
            {appInfo && (
              <span dangerouslySetInnerHTML={{ __html: appInfo.image }} />
            )}
          </h1>

          <div className="card-body">
            <form onSubmit={handleSubmit(handleSubmitLoginForm)}>
              <input type="hidden" name="csrf_token" value={csrf_token ? csrf_token : ''} />

              <FormItem
                labelFor={loginFields['email'].id}
                label={t(loginFields['email'].label)}
              >
                <InputField
                  id={loginFields['email'].id}
                  type={loginFields['email'].type}
                  placeholder={t(loginFields['email'].placeholder)}
                  {...register(loginFields['email'].name, loginFields['email'].validation)}
                  className={(generalErrors || errors.email) ? "error" : undefined}
                />

                {errors.email && <InputErrorMessage msg={t(emailErrorMessages[errors.email.type])} />}
              </FormItem>

              <FormItem
                label={t(loginFields['password'].label)}
                labelFor={loginFields['password'].id}
              >
                <InputField
                  id={loginFields['password'].id}
                  type={loginFields['password'].type}
                  placeholder={t(loginFields['password'].placeholder)}
                  iconName={loginFields['password'].iconName}
                  {...register(loginFields['password'].name, loginFields['password'].validation)}
                  className={(generalErrors || errors.password) ? "error" : undefined}
                />
                {errors.password && <InputErrorMessage msg={t(passwordErrorMessages[errors.password.type])} />}
                {generalErrors && <InputErrorMessage msg={t(generalErrors)} />}
              </FormItem>

              <div className="pt-3 pb-3">
                <Link to={`/${currentLang}/forgot?app_id=${idApp}&referer=${referer}`} className="fw-bold">
                  {t("Forgot Password")}
                </Link>
              </div>

              <div className="optext text-center w-100 pb-3">
                {t("Not registered?")} <Link to={`/${currentLang}/registration?app_id=${idApp}&referer=${referer}`}  className="fw-bold" >{t("Registration")}</Link>!
              </div>

              <FormItem>
                <Button
                  type="submit"
                  variant="default"
                  width={100}
                  height={48}
                  disabled={submitButton}
                  text={t("Login")}
                />
              </FormItem>

              <div className="p-3 optext pb-5">
                {t("Continuing you agree with our")} <Link to={`/${currentLang}/terms_and_conditions/`}>{t("Terms and Conditions")}</Link> {t("and")} <Link to={`/${currentLang}/privacy_policy/`}>{t("Privacy Policy")}</Link>.
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
