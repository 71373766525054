import React, { useContext } from "react";
import './Home.css'; 
const Home: React.FC = () => {
  return (
    <>
       
    </>
  );
};

export default Home;