export const svgConfig = {
  width: "24",
  height: "25",
  viewBox: "0 0 24 25",
  paths: [
    {
      d: "M12 22.7959C17.5228 22.7959 22 18.3187 22 12.7959C22 7.27305 17.5228 2.7959 12 2.7959C6.47715 2.7959 2 7.27305 2 12.7959C2 18.3187 6.47715 22.7959 12 22.7959Z",
      stroke: "white",
      strokeWidth: "2",
      strokeLinecap: "round" as const,
      strokeLinejoin: "round" as const,
      key: 1,
    },
    {
      d: "M2 12.7959H22",
      stroke: "white",
      strokeWidth: "2",
      strokeLinecap: "round" as const,
      strokeLinejoin: "round" as const,
      key: 2,
    },
    {
      d: "M12 2.7959C14.5013 5.53425 15.9228 9.08793 16 12.7959C15.9228 16.5039 14.5013 20.0575 12 22.7959C9.49872 20.0575 8.07725 16.5039 8 12.7959C8.07725 9.08793 9.49872 5.53425 12 2.7959Z",
      stroke: "white",
      strokeWidth: "2",
      strokeLinecap: "round" as const,
      strokeLinejoin: "round" as const,
      key: 3,
    },
  ],
};

