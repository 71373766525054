import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { SubmitHandler, useForm } from "react-hook-form";

import RegisterSkeleton from '../Skeletons/RegisterSkeleton'
import FormItem from "../../components/FormItem";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import InputErrorMessage from "../../components/InputErrorMessage";

import { getappInfo, handleErrorForms, getIdApp} from "../../utils";
import { generateRequestConfig } from "../../constants";
import { AuthContext } from "../../contexts/AuthContext";
import { 
  registrationFields, 
  getEmailErrorMessages, 
  getPasswordErrorMessages, 
  getPasswordRepeatErrorMessages, 
  RegistrationFormDataType 
} from "./Registration.constants";
import useTimer from "../../hooks/useTimer";

type RegistrationState = {
  generalErrors: string | undefined;
  submitButton: boolean;
  appInfo: any;
};

const Registration: React.FC = () => {
  type FormField = "email" | "password" | "passwordRepeat";

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const { AuthContextState } = useContext(AuthContext);
  const { csrf_token, idApp, referer } = AuthContextState;

  const [state, setState] = useState<RegistrationState>({
    appInfo: null,
    generalErrors: undefined,
    submitButton: true,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setError,
  } = useForm<RegistrationFormDataType>();

  const isLoaded = useTimer(600);

  useEffect(() => {
    document.title = t("Registration");
    
    if (!getIdApp()) {
      navigate('/');
    }

    if(!appInfo)
    {
      getappInfo((data) => setState(prevState => ({ ...prevState, appInfo: data })));
    }

  }, [t, i18n]);

  const handleSubmitRegistration: SubmitHandler<RegistrationFormDataType> = async (data) => {
    setState((prevState) => ({ ...prevState, submitButton: true }));
    setState((prevState) => ({ ...prevState, generalErrors: undefined }));
    
    const requestConfigRegistration = generateRequestConfig("post", "registration", {
      ...data,
      app_id: idApp
    }, {
      "X-CSRF-TOKEN": csrf_token,
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Accept-Language": currentLang,
      "Do-Not-Translate": 'en',
    });

    try {
      const res = await axios(requestConfigRegistration);
      if (res.status === 200) {
        const encodedEmail = encodeURIComponent(data.email);
        navigate(
          `/${currentLang}/activation/?app_id=${idApp}&referer=${referer}&email=${encodedEmail}`
        );
      }
    } catch (error) {
      const errorAction = handleErrorForms(error);
      if (errorAction) {
        const { message, field } = errorAction;
        if (field) {
          setError(field as FormField, {
            type: 'server',
            message: message,
          });
        } else {
          setState((prevState) => ({ ...prevState, generalErrors: message }));
        }
      }
      setState((prevState) => ({ ...prevState, submitButton: false }));
    }
  };

  const emailErrorMessages = getEmailErrorMessages(errors);
  const passwordErrorMessages = getPasswordErrorMessages(errors);
  const passwordRepeatErrorMessages = getPasswordRepeatErrorMessages(errors);

  const { appInfo, generalErrors, submitButton } = state;

  return (
    <>
      {!isLoaded ? <RegisterSkeleton /> : (
        <>
          <h1 className="text-center auth-title-form">
            {t("Registration")}
            {appInfo && (
              <span dangerouslySetInnerHTML={{ __html: appInfo.image }} />
            )}
          </h1>
          <div className="card-body">
            <form onSubmit={handleSubmit(handleSubmitRegistration)}>
              <input type="hidden" name="csrf_token" value={csrf_token ? csrf_token : ''} />

              <FormItem
                label={t(registrationFields['email'].label)}
                labelFor={registrationFields['email'].id}
              >
                <InputField
                  id={registrationFields['email'].id}
                  type={registrationFields['email'].type}
                  placeholder={t(registrationFields['email'].placeholder)}
                  {...register(
                    registrationFields['email'].name,
                    registrationFields['email'].validation
                  )}
                  className={(generalErrors || errors.email) ? "error" : undefined}
                />
                {errors.email && (
                  <InputErrorMessage msg={t(emailErrorMessages[errors.email.type])} />
                )}
              </FormItem>

              <FormItem
                label={t(registrationFields['password'].label)}
                labelFor={registrationFields['password'].id}
              >
                <InputField
                  id={registrationFields['password'].id}
                  type={registrationFields['password'].type}
                  placeholder={t(registrationFields['password'].placeholder)}
                  iconName={registrationFields['password'].iconName}
                  {...register(registrationFields['password'].name, {
                    ...registrationFields['password'].validation,
                    validate: (value) =>
                      value === watch("passwordRepeat") || t("Passwords don't match"),
                      onChange: () => trigger(registrationFields['password'].name),
                  })}
                  className={(generalErrors || errors.password) ? "error" : undefined}
                />
                {errors.password && (
                  <InputErrorMessage msg={t(passwordErrorMessages[errors.password.type])} />
                )}
              </FormItem>

              <FormItem
                label={t(registrationFields['passwordRepeat'].label)}
                labelFor={registrationFields['passwordRepeat'].id}
              >
                <InputField
                  id={registrationFields['passwordRepeat'].id}
                  type={registrationFields['passwordRepeat'].type}
                  placeholder={t(registrationFields['passwordRepeat'].placeholder)}
                  iconName={registrationFields['passwordRepeat'].iconName}
                  {...register(registrationFields['passwordRepeat'].name, {
                    ...registrationFields['passwordRepeat'].validation,
                    validate: (value) =>
                      value === watch("password") || t("Passwords don't match"),
                      onChange: () => trigger(registrationFields['password'].name),
                  })}
                  className={(generalErrors || errors.passwordRepeat) ? "error" : undefined}
                />
                {errors.passwordRepeat && (
                  <InputErrorMessage msg={t(passwordRepeatErrorMessages[errors.passwordRepeat.type])} />
                )}
                {generalErrors && <InputErrorMessage msg={t(generalErrors)} />}
              </FormItem>

              <FormItem>
                <div>
                  <input
                    id="agree"
                    type="checkbox"
                    {...register("agree", {
                      required: true,
                      onChange(event) {
                        setState((prevState) => ({ ...prevState, submitButton: !event.target.checked }));
                      },
                    })}
                  />
                  {t("By checking this box, I agree to the")} <Link to={`/${currentLang}/terms_and_conditions/`}>{t("Terms and Conditions")}</Link> {t("and")} <Link to={`/${currentLang}/privacy_policy/`}>{t("Privacy Policy")}</Link>.
                </div>
              </FormItem>

              <FormItem>
                <Button
                  variant="default"
                  width={100}
                  height={48}
                  disabled={submitButton}
                  text={t("Register")}
                />
              </FormItem>

              <div className="p-3 text-center pb-5">
                {t("Already have an account?")} 
                <Link to={`/${currentLang}/login?app_id=${idApp}`} className="fw-bold">
                  {t("Login")}
                </Link>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Registration;
