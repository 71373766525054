import { ValidationOptions } from "../../interfaces";
import { MIN_PASSWORD_LENGTH } from "../../constants";

export type ChangePasswordFormFields = "password" | "passwordRepeat";

export interface ChangePasswordFormDataType {
  password: string;
  passwordRepeat: string;
}

export interface ChangePasswordFields {
  id: string;
  type: string;
  label: string;
  placeholder: string;
  name: ChangePasswordFormFields;
  iconName?: string;
  validation: ValidationOptions;
}


export const changePasswordFields: Record<
ChangePasswordFormFields,
ChangePasswordFields
> = {
  password:{
    label: "Enter new password",
    id: "password",
    type: "password",
    name: "password",
    placeholder: "Password",
    iconName: "eye",
    validation: {
      required: true,
      minLength: MIN_PASSWORD_LENGTH,
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
    },
  },
  passwordRepeat:{
    label: "Confirm new password",
    id: "passwordRepeat",
    type: "password",
    name: "passwordRepeat",
    placeholder: "Password Repeat",
    iconName: "eye",
    validation: {
      required: true,
      minLength: MIN_PASSWORD_LENGTH,
    },
  },
};

export const getpasswordErrorMessages = ({
  password,
}: any): Record<string, string> => ({
  required: "Password is required",
  pattern: "Password must be at least 8 characters long, contain both uppercase and lowercase letters, include at least one numeric digit, and one special character.",
  minLength: "Password is too short",
  server: password?.message || "Server error",
});


