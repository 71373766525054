import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import "./Button.css";

export const buttonVariants = cva("", {
  variants: {
    variant: {
      default: "btn btn-dark",
      outline: "btn globe",
      light: "btn btn-light",
      text: "btn-text",
    },
    width: {
      100: "w-100",
    },
    minWidth: {
      128: "mw-128",
    },
    height: {
      48: "h48",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface IProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  children?: ReactNode;
  text?: string;
}

const Button = (props: IProps) => {
  const { variant, width, height, children, minWidth, text, ...rest } = props;
  return (
    <button
      className={buttonVariants({ variant, width, height, minWidth })}
      {...rest}
    >
      {children}
      {text}
    </button>
  );
};


export default Button;
