import React, { ReactNode } from "react";

interface FormItemProps {
  label?: string;
  children: ReactNode;
  labelFor?: string;
}

const FormItem: React.FC<FormItemProps> = ({ label, children, labelFor }) => {
  return (
    <div className="pb20">
      {label && (
        <label htmlFor={labelFor} className="label-text">
          {label}
        </label>
      )}
      {children}
    </div>
  );
};

export default FormItem;
