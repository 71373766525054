import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import "./Skeleton.css";

const skeletonVariants = cva("skeleton-pulse", {
  variants: {
    animation: {
      enabled: "",
      disabled: "no-animation",
    },
    shape: {
      default: "",
      circle: "skeleton-circle",
    },
    display: {
      block: "",
      inline: "skeleton-inline",
    },
  },
  defaultVariants: {
    animation: "enabled",
    shape: "default",
    display: "block",
  },
});

interface SkeletonProps extends VariantProps<typeof skeletonVariants> {
  count?: number;
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
  className?: string;
  containerClassName?: string;
  style?: React.CSSProperties;
  wrapper?: React.FC<React.PropsWithChildren<unknown>>;
}

const Skeleton: React.FC<SkeletonProps> = (props) => {
  const {
    count = 1,
    width,
    height,
    borderRadius = "0.25rem",
    shape,
    className = "",
    containerClassName = "",
    display,
    animation,
    style = {},
    wrapper: Wrapper,
  } = props;

  const elements = [];
  const countArray = [...Array(Math.floor(count))];
  const decimal = count % 1;

  const skeletonStyle: React.CSSProperties = {
    width,
    height,
    borderRadius: shape === "circle" ? "50%" : borderRadius,
    opacity: 0.7,
    ...style,
  } as React.CSSProperties;

  const skeletonClasses = skeletonVariants({
    animation: animation ? "enabled" : "disabled",
    shape,
    display,
    className,
  });

  countArray.forEach((_, index) => {
    const element = (
      <React.Fragment key={index}>
        <span className={skeletonClasses} style={skeletonStyle} />
        {display !== "inline" && <br />}
      </React.Fragment>
    );

    elements.push(Wrapper ? <Wrapper key={index}>{element}</Wrapper> : element);
  });

  if (decimal) {
    const element = (
      <React.Fragment key="decimal">
        <span
          className={skeletonClasses}
          style={{ ...skeletonStyle, width: `${decimal * 100}%` }}
        />
        {display !== "inline" && <br />}
      </React.Fragment>
    );
    elements.push(
      Wrapper ? <Wrapper key="decimal">{element}</Wrapper> : element
    );
  }

  return (
    <span className={`skeleton-container ${containerClassName}`}>
      {elements}
    </span>
  );
};

export default Skeleton;
