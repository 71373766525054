import React, { useState, forwardRef, Ref } from "react";
import EyeIcon from "../../assets/img/eye.svg";
import EyeSlashIcon from "../../assets/img/eye-slash.svg";
import "./InputField.css";
interface InputFieldProps extends React.HTMLProps<HTMLInputElement> {
  iconName?: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField = forwardRef((props: InputFieldProps, ref: Ref<HTMLInputElement>) => {
  const { iconName, className, onChange, type, maxLength, ...rest } = props;
  const [ showPassword, setShowPassword ] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderIcon = () => {
    if (iconName === "eye") {
      return (
        <img
          src={showPassword ? EyeIcon: EyeSlashIcon }
          alt="eye-slash"
          className="span-icon"
          onClick={togglePasswordVisibility}
        />
      );
    }
  };

  return (
    <div
      className={
        className
          ? `input-group h48 has-validation ${className}`
          : "input-group h48 has-validation"
      }
    >
      <input
        ref={ref}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        onChange={onChange}
        className="form-control"
        maxLength={maxLength}
        {...rest}
      />
      {iconName && renderIcon()}
    </div>
  );
});

export default InputField;
