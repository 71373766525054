import {  ValidationOptions  } from "../../interfaces";

export type ActivationFormFields = "code";

export interface ActivationFormDataType {
  code: number;
}

export interface ActivationFields {
  id: string;
  type: string;
  label: string;
  placeholder: string;
  iconName?: string;
  name: ActivationFormFields;
  validation: ValidationOptions;
}


export const activationFields: Record<
  ActivationFormFields,
  ActivationFields
> = {
  code: {
    label: "Enter 6 digit code sent via email",
    id: "code",
    type: "code",
    name: "code",
    placeholder: "Code",
    validation: {
      required: true,
      maxLength: 6,
      pattern: /^[0-9]{6}$/,
    },
  },
};


export const getCodeErrorMessages = ({
  code,
}: any): Record<string, string> => ({
  required: "Code is required",
  pattern: "Invalid Code format",
  maxLength: "Code is too long",
  server: code?.message || "Server error",
});
