import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';

interface IProps {
  statusCode?: number;
  title?: string;
}

const ErrorHandler = ({ statusCode = 500, title = "Server Error" }: IProps) => {
  const { pathname } = useLocation();

  return (
    <Container className="d-flex align-items-center justify-content-center vh-100">
      <Row className="text-center">
        <Col>
          <div className="d-inline-flex rounded bg-danger p-4">
            {/* SVG icon */}
          </div>
          <h2 className="mt-5">
            {statusCode} - {title}
          </h2>
          <p className="mt-5">
            Oops something went wrong. Try to refresh this page or <br /> feel free to contact us if the problem persists.
          </p>
          <div className="d-flex align-items-center justify-content-center mt-5">
            <Link to={pathname}>
              <Button variant="primary">Refresh</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorHandler;