import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import FormItem from "../../components/FormItem";
import InputField from "../../components/InputField";
import { getappInfo, handleErrorForms, getIdApp} from "../../utils";
import Button from "../../components/Button";
import InputErrorMessage from "../../components/InputErrorMessage";
import { SubmitHandler, useForm } from "react-hook-form";
import { resetPasswordFields, getEmailErrorMessages, ResetPasswordFormDataType } from "./ResetPassword.constants";
import { generateRequestConfig } from "../../constants";
import { AuthContext } from "../../contexts/AuthContext";
import useTimer from "../../hooks/useTimer";
import ResetPasswordSkeleton from "../Skeletons/ResetPasswordSkeleton";

type ResetPasswordState = {
  generalErrors: string | undefined;
  submitButton: boolean;
  appInfo: any;
};


const ResetPassword: React.FC = () => {
  type FormField = "email";

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const { AuthContextState } = useContext(AuthContext);
  const { csrf_token, idApp, referer } = AuthContextState;
  
  const [state, setState] = useState<ResetPasswordState>({
    appInfo: null,
    generalErrors: undefined,
    submitButton: false,
  });
  const isLoaded = useTimer(400);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ResetPasswordFormDataType>();

  useEffect(() => {
    document.title = t("Reset password");
    
    if (!getIdApp()) {
      navigate('/');
    }

    if(!appInfo)
    {
      getappInfo((data) => setState(prevState => ({ ...prevState, appInfo: data })));
    }

  }, [t, i18n]);

  const handleSubmitResetPasswordForm: SubmitHandler<
    ResetPasswordFormDataType
  > = async (data) => {
    
    setState((prevState) => ({ ...prevState, submitButton: true }));
    setState((prevState) => ({ ...prevState, generalErrors: undefined }));

    const requestConfigRegistration = generateRequestConfig(
      "post",
      "resetpassword",
      {
        app_id: idApp,
        ...data,
      },
      {
        "X-CSRF-TOKEN": csrf_token,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": currentLang,
        "Do-Not-Translate": 'en',
      }
    );

    try {
      const res = await axios(requestConfigRegistration);
      
      if (res.status === 200) {
        const encodedEmail = encodeURIComponent(data.email);
        navigate(
          `/${currentLang}/activation/?app_id=${idApp}&referer=${referer}&email=${encodedEmail}&confirm=confirm`
        );
      }
      
    } catch (error) {
      const errorAction = handleErrorForms(error);
      if (errorAction) {
        const { message, field } = errorAction;
        if (field) {
          setError(field as FormField, {
            type: "server",
            message: message,
          });
        } else {
          setState((prevState) => ({ ...prevState, generalErrors: message }));
        }
      }
      setState((prevState) => ({ ...prevState, submitButton: true }));
    }
  };

  const emailErrorMessages = getEmailErrorMessages(errors);
  const { appInfo, generalErrors, submitButton } = state;
  return (
    <>
      {!isLoaded ? (
        <ResetPasswordSkeleton />
      ) : (
        <>
          <h1 className="text-center auth-title-form">
            {t("Reset password")}
            {appInfo && (
              <span dangerouslySetInnerHTML={{ __html: appInfo.image }} />
            )}
          </h1>
          <div className="card-body">
            <form onSubmit={handleSubmit(handleSubmitResetPasswordForm)}>
              <input
                type="hidden"
                name="csrf_token"
                value={csrf_token ? csrf_token : ""}
              />
              <FormItem
                label={t(resetPasswordFields["email"].label)}
                labelFor={resetPasswordFields["email"].id}
              >
                <InputField
                  id={resetPasswordFields["email"].id}
                  type={resetPasswordFields["email"].type}
                  placeholder={t(resetPasswordFields["email"].placeholder)}
                  {...register(
                    resetPasswordFields["email"].name,
                    resetPasswordFields["email"].validation
                  )}
                  className={
                    generalErrors || errors.email ? "error" : undefined
                  }
                />
                {errors.email && (
                  <InputErrorMessage
                    msg={t(emailErrorMessages[errors.email.type])}
                  />
                )}
                {generalErrors && <InputErrorMessage msg={t(generalErrors)} />}
              </FormItem>

              <FormItem>
                <Button
                  variant="default"
                  width={100}
                  height={48}
                  disabled={submitButton}
                  text={t("Send")}
                />
              </FormItem>

              <FormItem>
                <Button
                  variant="text"
                  width={100}
                  height={48}
                  text={t("Back")}
                  onClick={() =>
                    navigate(`/${currentLang}/login?app_id=${idApp}`)
                  }
                />
              </FormItem>

              <div className="p-3 optext pb-5">
                {t("Continuing you agree with our")}{" "}
                <Link to={`/${currentLang}/terms_and_conditions/`}>
                  {t("Terms and Conditions")}
                </Link>{" "}
                {t("and")}{" "}
                <Link to={`/${currentLang}/privacy_policy/`}>
                  {t("Privacy Policy")}
                </Link>
                .
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default ResetPassword;
