import React from "react";
import Skeleton from "../../components/Skeleton/Skeleton";

const ResetPasswordSkeleton: React.FC = () => {
  return (
    <>
      <div className="text-center auth-title-form">
        <Skeleton
          width={200}
          height={20}
          className="mx-auto"
          animation={"enabled"}
        />
      </div>

      <div className="card-body">
        <div className="mb-2">
          <Skeleton
            width={80}
            height={20}
            className="mb-1"
            animation={"enabled"}
          />
          <Skeleton height={48} className="w-100" animation={"enabled"} />
        </div>

        <div className="mb-2">
          <Skeleton height={48} className="w-100" animation={"enabled"} />
        </div>

        <div className="mb-2 text-center">
          <Skeleton height={20} width={80} animation={"enabled"} />
        </div>

        <div className="p-3 pb-5">
          <Skeleton
            width={200}
            height={20}
            className="mx-auto"
            animation={"enabled"}
            count={1.90}
          />
        </div>
      </div>
    </>
  );
};

export default ResetPasswordSkeleton;
