import React, { useContext } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import ErrorHandler from "../components/errors/ErrorHandler";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Activation from "../pages/Activation";
import Registration from "../pages/Registration";
import PageNotFound from "../components/PageNotFound";
import Layout from "../components/Layout"; 
import ChangePassword from "../pages/ChangePassword";
import ResetPassword from "../pages/ResetPassword";
import { AuthContext } from "../contexts/AuthContext";
import { projectBasename } from "../constants";
import { useTranslation } from "react-i18next";

const Router = () => {
  const { AuthContextState } = useContext(AuthContext);
  const { isAuthenticated,idApp } = AuthContextState;
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* Root Layout */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/:lang/logout"
            element={
              <ProtectedRoute isAuth={!isAuthenticated} redirectPath={`/${currentLang}/login?app_id=${idApp}`} >
                <Logout />
              </ProtectedRoute>
            }
            errorElement={<ErrorHandler />}
          />
          <Route
            path="/:lang/registration"
            element={
              <ProtectedRoute isAuth={isAuthenticated} redirectPath={`/${currentLang}/logout?app_id=${idApp}`}>
                <Registration />
              </ProtectedRoute>
            }
            errorElement={<ErrorHandler />}
          />
          <Route
            path="/:lang/activation"
            element={
              <ProtectedRoute isAuth={isAuthenticated} redirectPath={`/${currentLang}/logout?app_id=${idApp}`}>
                <Activation />
              </ProtectedRoute>
            }
            errorElement={<ErrorHandler />}
          />
          <Route
            path="/:lang/login"
            element={
              <ProtectedRoute isAuth={isAuthenticated} redirectPath={`/${currentLang}/logout?app_id=${idApp}`}>
                <Login />
              </ProtectedRoute>
            }
            errorElement={<ErrorHandler />}
          />
          <Route
            path="/:lang/forgot"
            element={
              <ProtectedRoute isAuth={isAuthenticated} redirectPath={`/${currentLang}/logout?app_id=${idApp}`}>
                <ResetPassword />
              </ProtectedRoute>
            }
            errorElement={<ErrorHandler />}
          />
          <Route
            path="/:lang/change-password"
            element={
              <ProtectedRoute isAuth={isAuthenticated} redirectPath={`/${currentLang}/logout?app_id=${idApp}`}>
                <ChangePassword />
              </ProtectedRoute>
            }
            errorElement={<ErrorHandler />}
          />
        </Route>

        {/* Page Not Found */}
        <Route path="*" element={<PageNotFound />} />
      </>
    ),
    { basename: projectBasename }
  );

  return <RouterProvider router={router} />;
};

export default Router;
