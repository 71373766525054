import React, { ReactNode, useContext} from "react";
import { Navigate } from "react-router-dom";

interface IProps {
  isAuth: boolean;
  redirectPath: string;
  children: ReactNode;
  data?: unknown;
}

const ProtectedRoute = ({ isAuth, redirectPath, children, data }: IProps) => {
  if(isAuth) return <Navigate to={redirectPath} replace state={data} />;
  return <>{children}</>;
};

export default ProtectedRoute;
