import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import { SubmitHandler, useForm } from "react-hook-form";
import { AxiosError } from 'axios';

import FormItem from "../../components/FormItem";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import InputErrorMessage from "../../components/InputErrorMessage";

import { generateRequestConfig } from "../../constants";
import { AuthContext } from "../../contexts/AuthContext";
import { getappInfo, handleErrorForms, getEmailFromParams, getDeviceId} from "../../utils";

import { activationFields, getCodeErrorMessages, ActivationFormDataType } from "./Activation.constants";
import { ActivationFormText, HttpMethod, Endpoint } from "../../interfaces";
import useTimer from "../../hooks/useTimer";
import ActivationSkeleton from "../Skeletons/ActivationSkeleton";

type ActivationState = {
  code: string;
  countdown: number;
  generalErrors: string | undefined;
  resendButton: boolean;
  submitButton: boolean;
  appInfo: any;
};

const Activation: React.FC = () => {
  type FormField = "code";

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const userEmail = getEmailFromParams();
  const params = new URLSearchParams(location.search);
  
  const { handleLogIn, AuthContextState } = useContext(AuthContext);
  const { csrf_token, idApp, referer } = AuthContextState;
  
  const [state, setState] = useState<ActivationState>({
    code: "",
    countdown: 60, // 60 seconds 
    generalErrors: undefined,
    resendButton: false,
    submitButton: false,
    appInfo: null,
  });
  
  const [formText, setFormText] = useState<ActivationFormText>({
    formTitle: "Activate the account",
    buttonTitle: "Activate",
  });

  const { register, handleSubmit, formState: { errors }, setError } = useForm<ActivationFormDataType>();
  const isLoaded = useTimer(600);

  useEffect(() => {
    document.title = t("Activation");

    if (!userEmail) {
      navigate(`/${currentLang}/registration/?app_id=${idApp}&referer=${referer}`);
    }

    if(!appInfo)
    {
      getappInfo((data) => setState(prevState => ({ ...prevState, appInfo: data })));
    }

    const blockedUntil = Cookies.get("blockedUntil");
    if (blockedUntil) {
      const remainingTime = Math.floor((parseInt(blockedUntil, 10) - Date.now()) / 1000);
      if (remainingTime > 0) {
        startCountdown(remainingTime);
      }
    }

    if (params.has('confirm')) {
      setFormText({
        formTitle: "Confirm your account",
        buttonTitle: "Confirm"
      });
    }
  }, [t, i18n]);

  const startCountdown = (initialSeconds: number) => {
      if(initialSeconds==0){
        return ;
      }

    setState(prevState => ({
      ...prevState,
      resendButton: true,
      countdown: initialSeconds,
    }));

    const countdownInterval = setInterval(() => {
      setState(prevState => {
        const updatedCountdown = prevState.countdown - 1;

        if (updatedCountdown <= 0) {
          setState(prevState => ({ ...prevState, generalErrors: undefined }));
          clearInterval(countdownInterval);
          return {
            ...prevState,
            resendButton: false,
            countdown: initialSeconds,
          };
        }

        return {
          ...prevState,
          countdown: updatedCountdown,
        };
      });
    }, 1000);
  };

  const handleResendCode = async () => {
    setState(prevState => ({ ...prevState, generalErrors: undefined }));
    
    
    const isconfurmation = params.has('confirm') ? "confirmation" : "activation";

    const requestConfigActivation = generateRequestConfig(
      "put",
      "resendcode",
      {
        email: userEmail,
        app_id: idApp,
        "confirmation" : isconfurmation,
      },
      {
        "X-CSRF-TOKEN": csrf_token,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": currentLang,
        "Do-Not-Translate": 'en',
      }
    );

    try {
      const res = await axios(requestConfigActivation);
      setState(prevState => ({ ...prevState, generalErrors: res.data.message }));
      
      
      res.data.remaining_time
      if(res.data.remaining_time)
        setState((prevState) => ({ ...prevState, countdown: res.data.remaining_time }));
        state.countdown = res.data.remaining_time;
        startCountdown(state.countdown);
        const blockedUntil = Date.now() + state.countdown * 1000;
        Cookies.set("blockedUntil", blockedUntil.toString(), {
          expires: new Date(blockedUntil + state.countdown * 1000),
        });

    } catch (error) {
      
      if (error instanceof AxiosError) {
        if(error.response &&  error.response.data.remaining_time){
          let res = error.response;
          setState((prevState) => ({ ...prevState, countdown: res.data.remaining_time }));
          state.countdown = error.response.data.remaining_time;
          startCountdown(state.countdown);

          const blockedUntil = Date.now() + state.countdown * 1000;
          Cookies.set("blockedUntil", blockedUntil.toString(), {
            expires: new Date(blockedUntil + state.countdown * 1000),
          });
        }
        
      }  

      

      const errorAction = handleErrorForms(error);
      if (errorAction) {
        const { message, field } = errorAction;
        if (field) {
          setError(field as FormField, {
            type: "server",
            message: message,
          });
        } else {
          setState(prevState => ({ ...prevState, generalErrors: message }));
        }
      }
    }
  };
  interface CountdownProps {
    countdown: number;  
  }
  
  const CountdownDisplay: React.FC<CountdownProps> = ({ countdown }) => {
    const minutes = Math.floor(countdown / 60);  // Calculate minutes
    const seconds = countdown % 60;              // Calculate seconds
  
    return (
      <div>
        {minutes > 0 ? (
          <>
            {t("Resend in")} {minutes}:{seconds < 10 ? `0${seconds}` : seconds} {t("minutes")}
          </>
        ) : (
          <>
            {t("Resend in")} {seconds} {t("seconds")}
          </>
        )}
      </div>
    );
  };


  const handleSubmitActivation: SubmitHandler<ActivationFormDataType> = async (data) => {
    setState((prevState) => ({ ...prevState, submitButton: true }));
    setState(prevState => ({ ...prevState, generalErrors: undefined }));

    const httpMethod: HttpMethod = params.has('confirm') ? "put" : "put";
    const endPoint: Endpoint = params.has('confirm') ? "confirmation" : "activation";

    const requestConfigActivation = generateRequestConfig(
      httpMethod,
      endPoint,
      {
        email: userEmail,
        app_id: idApp,
        device_id: getDeviceId(),
        ...data,
      },
      {
        "X-CSRF-TOKEN": csrf_token,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": currentLang,
        "Do-Not-Translate": 'en',
      }
    );

    try {
      const res = await axios(requestConfigActivation);

      if (res.status === 202) {
        const expirationDate = new Date(res.data.resetpassword_access_token_exp_at);
        Cookies.set('x-reset-password-token', res.data.resetpassword_access_token, { expires: expirationDate });
        navigate(`/${currentLang}/change-password/?app_id=${idApp}`);
      }

      if (res.status === 200) {
        handleLogIn(res.data);
      }

    } catch (error) {
      const errorAction = handleErrorForms(error);
      if (errorAction) {
        const { message, field } = errorAction;
        if (field) {
          setError(field as FormField, {
            type: "server",
            message: message,
          });
        } else {
          setState(prevState => ({ ...prevState, generalErrors: message }));
        }
      }
      setState((prevState) => ({ ...prevState, submitButton: true }));
    }
  };

  const { resendButton, submitButton, generalErrors, appInfo, countdown } = state;
  const codeErrorMessages = getCodeErrorMessages(errors);

  return (
    <>
      {!isLoaded ? (
        <ActivationSkeleton />
      ) : (
        <>
          <h1 className="text-center auth-title-form">
            {t(formText.formTitle)}
            {appInfo && (
              <span dangerouslySetInnerHTML={{ __html: appInfo.image }} />
            )}
          </h1>

          <div className="card-body">
            <form method="POST" onSubmit={handleSubmit(handleSubmitActivation)}>
              <input
                type="hidden"
                name="csrf_token"
                value={csrf_token ? csrf_token : ""}
              />
              <input type="hidden" name="email" value={userEmail} />

              <FormItem
                label={t(activationFields["code"].label)}
                labelFor={activationFields["code"].id}
              >
                <InputField
                  id={activationFields["code"].id}
                  type={activationFields["code"].type}
                  placeholder={t(activationFields["code"].placeholder)}
                  {...register(
                    activationFields["code"].name,
                    activationFields["code"].validation
                  )}
                  className={generalErrors || errors.code ? "error" : undefined}
                />
                {errors.code && (
                  <InputErrorMessage
                    msg={t(codeErrorMessages[errors.code.type])}
                  />
                )}
                {generalErrors && <InputErrorMessage msg={t(generalErrors)} />}

                <div className="optext w-100 pb-3">
                  <button
                    type="button"
                    className={`btn p-0 ${resendButton ? "d-none" : ""}`}
                    onClick={handleResendCode}
                  >
                    {t("Re-send new code")}!
                  </button>
                  {resendButton && (
                    <span>
                      <CountdownDisplay countdown={countdown} />
                    </span>
                  )}
                </div>
              </FormItem>

              <FormItem>
                <Button
                  type="submit"
                  variant="default"
                  width={100}
                  height={48}
                  disabled={submitButton}
                  text={t(formText.buttonTitle)}
                />
              </FormItem>

              <div className="p-3 text-center pb-5">
                {t("Already have an account?")}{" "}
                <Link
                  to={`/${currentLang}/login?app_id=${idApp}&referer=${referer}`}
                  className="fw-bold"
                >
                  {t("Login")}
                </Link>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Activation;
