import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { svgConfig } from "./LanguageSelectorButton.constants";
import "./LanguageSelectorButton.css";
import Button from "../Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { generateRequestConfig } from "../../constants";
import { Language, iLanguage } from "../../interfaces";
import { handleErrorForms } from "../../utils";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [languages, setLanguages] = useState<Language[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleChangeLanguage(i18n.language);
    getDataLanguages();
  }, []);

  const getDataLanguages = async (): Promise<void> => {
    try {
      const requestConfigLanguages = generateRequestConfig(
        "get",
        "languages",
        {},
        { "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        {
          items_per_page: 100,
          action: "GetData",
        }
      );
      const response = await axios(requestConfigLanguages);
      const modifiedLanguages = response.data.data.map((language:iLanguage) => ({
        id: language.ISO_639_1.toLowerCase(),
        name: language.name,
        flag: language.flag,
      }));
      setLanguages(modifiedLanguages);
    } catch (error) {
      handleErrorForms(error);
    }
  };

  const handleChangeLanguage = (lang: string) => {
    lang = lang.toLowerCase();
    i18n.changeLanguage(lang);
    const currentUrl = new URL(window.location.href);
    const path = currentUrl.pathname + currentUrl.search;
    const newPath = path.replace(/^\/([A-Za-z]{2})\//, (match, p1) => {
      return match.replace(p1, lang);
    });
    navigate(newPath);
    setShowDropdown(false);
  };

  const handleToggleList = () => {
    setShowDropdown((prev) => !prev);
  };

  const renderDropdown = () => {
    if (!showDropdown) {
      return null;
    }
    return (
      <div className="list-text dropdown">
        {languages.map((language) => (
          <button
            key={language.id}
            type="button"
            className={
              language.id === i18n.language
                ? "dropdown-item dropdownItem selected"
                : "dropdown-item dropdownItem"
            }
            onClick={() => handleChangeLanguage(language.id)}
          >
            {language.flag} {language.name}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div
      className={
        showDropdown ? "globeContainer showDropdown" : "globeContainer"
      }
    >
      <Button variant="outline" minWidth={128} onClick={handleToggleList}>
        <svg
          width={svgConfig.width}
          height={svgConfig.height}
          viewBox={svgConfig.viewBox}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {svgConfig.paths.map((path, index) => (
            <path key={index} d={path.d} />
          ))}
        </svg>{" "}
        {languages.find((lang) => lang.id === i18n.language)?.name}  
      </Button>

      {renderDropdown()}
    </div>
  );
};

export default LanguageSelector;
