import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { AuthContextProps, AuthProviderProps, AuthContextState, User, LoginData, LogoutData} from "../interfaces";
import {fetchCsrfToken, getIdApp, acessToken, referer, getDeviceId } from "../utils";
import { generateRequestConfig } from "../constants";

const initialAuthContextState: AuthContextState = {
  user: { email: null, name: null },
  csrf_token: null,
  login: null,
  logout: null,
  access_token:acessToken(), 
  idApp: getIdApp(),
  referer: referer(),
  refreshTimeoutId:null,
  isAuthenticated: acessToken() ? true : false,
};

export const AuthContext = createContext<AuthContextProps>({
  AuthContextState: initialAuthContextState,
  handleLogIn: () => {},
  handleLogOut: () => {},
  redirectToApp:() => {},
  checkIsAuthenticated: () => {},
  setAuthContextState: () => {}
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [AuthContextState, setAuthContextState] = useState<AuthContextState>(initialAuthContextState);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCsrfToken(setAuthContextState);
      checkIsAuthenticated();
      scheduleTokenRefresh();
    };
    fetchData();
  }, []);

  useEffect(() => {
  
  }, [AuthContextState]);

  const checkIsAuthenticated = async () => {

    const access_token = acessToken();
    let user: User = { email: null, name: null};
    
    if (access_token) {

      user = jwtDecode<User>(access_token);
      await setAuthContextState((prevState) => ({
        ...prevState,
        user: user,
        isAuthenticated: access_token ? true : false,
        referer:referer(),
        idApp: getIdApp()
      }));

    }else{

      await setAuthContextState((prevState) => ({
        ...prevState,
        user: user,
        isAuthenticated: false,
        referer:referer(),
        idApp: getIdApp()
      }));
    }

  };

  const scheduleTokenRefresh = () => {

    setAuthContextState((prevState) => {
      const { refreshTimeoutId } = prevState;
   
    if (refreshTimeoutId !== null) {
      clearTimeout(refreshTimeoutId);
    }

    const expirationTimeStr = localStorage.getItem('access_token_exp_at');
   
    if (expirationTimeStr) {
      const expirationTime = new Date(expirationTimeStr);
      const userLocalTime = new Date(expirationTime.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
      const currentTime = new Date();
      const refreshTime = userLocalTime.getTime() - (3 * 60 * 1000); 
      const timeUntilRefresh = refreshTime - currentTime.getTime();

      if (timeUntilRefresh > 0) {
        const timeoutId = setTimeout(async () => {
          try {
            await refreshToken();
          } catch (error) {
            console.error('Erro token refresh:', error);
          }
        }, timeUntilRefresh);

        setAuthContextState((prevState) => ({
          ...prevState,
          refreshTimeoutId: timeoutId,
        }));
      }  
    }
    return prevState;
  });
  };

  const refreshToken = async () => {

    const requestConfigLogin = generateRequestConfig("post", "refresh", {
       refresh_token: Cookies.get('x-refresh-token'),
       device_id: getDeviceId()
    }, {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      "Pragma": "no-cache",
      "Expires": "0"
    });
     
    try {
      const response = await axios(requestConfigLogin);
      const { access_token, refresh_token, access_token_exp_at, refresh_token_exp_at } = response.data;
      
      Cookies.set("x-auth-token", access_token, { expires: new Date(access_token_exp_at), secure: false, domain:'.id.express' });
      Cookies.set("x-refresh-token", refresh_token, { expires: new Date(refresh_token_exp_at), secure: false, domain:'.id.express' });

      localStorage.setItem('access_token_exp_at', access_token_exp_at.toString() );
      localStorage.setItem('refresh_token_exp_at', refresh_token_exp_at.toString() );
      
      await setAuthContextState((prevState) => ({
        ...prevState,
        access_token: access_token,
      }));
      scheduleTokenRefresh();
      
    } catch (error) {
        let user: User = { email: null, name: null};

        await setAuthContextState((prevState) => ({
          ...prevState,
          user: user,
          isAuthenticated: false,
          referer:referer(),
          idApp: getIdApp()
        }));

        handleLogOut({
          logout: 'logout'
        });
    }
  };

  const redirectToApp = () => {
   
    const { login, logout } = AuthContextState;
    const ref = referer()

    if(ref){
      if(login){
        window.location.href = `${ref}?login=${login}`;
      }
      if(logout){
        window.location.href = `${ref}?logout=${logout}`;
      }
    }
  }

  const handleLogIn = async (data: LoginData) => {

    const login = data.login;
    const url_callback = data.url_callback;
    const ref = referer();
    
    const access_token = data.access_token;
    const access_token_exp_at = new Date(data.access_token_exp_at);

    const refresh_token = data.refresh_token;
    const refresh_token_exp_at = new Date(data.refresh_token_exp_at);

    if (access_token &&  login) {

      Cookies.set("x-auth-token", access_token, { expires: new Date(access_token_exp_at), secure: false, domain:'.id.express' });
      Cookies.set("x-refresh-token", refresh_token, { expires: new Date(refresh_token_exp_at), secure: false, domain:'.id.express' });
      localStorage.setItem('access_token_exp_at', data.access_token_exp_at.toString() );
      localStorage.setItem('refresh_token_exp_at', data.refresh_token_exp_at.toString() );

      await setAuthContextState((prevState) => ({
        ...prevState,
        'login': login,
        'referer': ref ?? url_callback ?? null
      }));

      checkIsAuthenticated();
      scheduleTokenRefresh();
    }
  };

  const handleLogOut = async (data: LogoutData) => {
    Cookies.remove("x-auth-token", { domain: 'id.express' });
    Cookies.remove("x-refresh-token", { domain: 'id.express' });
    
    
    localStorage.removeItem('access_token_exp_at');
    localStorage.removeItem('refresh_token_exp_at');


    const logout = data.logout;
    const url_callback = data.url_callback ?? null;
    const ref = referer();
    await setAuthContextState((prevState) => ({
      ...prevState,
      'logout': logout ?? null,
      'referer': ref ?? url_callback ?? null
    }));
    checkIsAuthenticated();
  };

  return (
    <AuthContext.Provider
      value={{
        AuthContextState,
        checkIsAuthenticated,
        redirectToApp,
        handleLogIn,
        handleLogOut,
        setAuthContextState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};