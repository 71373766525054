import React, { useState } from "react";
import { Container } from "react-bootstrap";
import i_logo from "../../assets/img/id.express.svg";
import LanguageSelector from "../LanguageSelectorButton";
import "./Layout.css";
import { Outlet } from "react-router-dom";
import Skeleton from "../Skeleton/Skeleton";
import { useTranslation } from "react-i18next";
import { LinkOrSkeletonProps, ImageLinkFooterProps } from "../../interfaces";
import { getFooterLinks, getImageLinks } from './Layout.constants';
import useTimer from "../../hooks/useTimer";

const Layout = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const currentYear = new Date().getFullYear();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const isLinksLoaded = useTimer(500);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const renderLinkOrSkeleton = (props: LinkOrSkeletonProps): JSX.Element => {
    const { isLoaded, href, text, width } = props;
    return isLoaded ? (
      <a href={href}>{text}</a>
    ) : (
      <Skeleton width={width} height="20px" animation="enabled" display="inline" />
    );
  };

  const renderImageOrSkeleton = (props: ImageLinkFooterProps): JSX.Element => {
    const { isLoaded, src, alt, width, height, onLoad, className } = props;
    return (
      <a href={src} className={className}>
        {!isLoaded && (
          <Skeleton width={width} height={height} animation="enabled" />
        )}
        <img
          src={src}
          alt={alt}
          onLoad={onLoad}
          style={{ display: !isLoaded ? "none" : undefined }}
        />
      </a>
    );
  };
  
  return (
    <div className="auth-background">
      <LanguageSelector />
      <Container>
        <div className="text-center logoext">
          {!isImageLoaded && <Skeleton width="180px" height="53px" animation="enabled" />}
          <img
            src={i_logo}
            alt="id.Express"
            onLoad={handleImageLoad}
            style={{ display: !isImageLoaded ? "none" : undefined }}
          />
        </div>
        <div className="justify-content-center align-items-center d-flex flex-column">
          <div className="auth-card"><Outlet /></div>
          <footer className="py-3 my-4">
            <ul className="nav justify-content-center pb-3 mb-3">
              {getImageLinks(isImageLoaded, handleImageLoad).map((imageProps, index) => (
                <li className="nav-item" key={imageProps.src}>
                  {renderImageOrSkeleton(imageProps)}
                </li>
              ))}
            </ul>
            <p className="text-center footer-links">
              {getFooterLinks(isLinksLoaded, currentYear, currentLang, t).map((linkProps, index) => (
                <span key={linkProps.href}>
                  {renderLinkOrSkeleton(linkProps)}
                </span>
              ))}
            </p>
          </footer>
        </div>
      </Container>
    </div>
  );
};

export default Layout;