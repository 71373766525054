import { AxiosRequestConfig } from "axios";
import { HttpMethod, Endpoint, EndpointMap } from "./interfaces";

// general configuration
export const projectName = "id.express";
export const projectApiUrl = process.env.REACT_APP_API_URL;
export const projectBasename = "/"; // base path 
export const defaultLanguage = "en";

// apiEndpoints
const endpointMap: EndpointMap = {
  login: `${projectApiUrl}/login`,
  oneclicklogin: `${projectApiUrl}/oneclicklogin`,
  logout: `${projectApiUrl}/logout`,
  registration: `${projectApiUrl}/registration`,
  resendcode: `${projectApiUrl}/resendcode`,
  activation: `${projectApiUrl}/activation`,
  languages: `${projectApiUrl}/languages`,
  get_csrf_token: `${projectApiUrl}/get_csrf_token`,
  apps: `${projectApiUrl}/apps?`,
  resetpassword: `${projectApiUrl}/resetpassword`,
  confirmation: `${projectApiUrl}/confirmation`,
  refresh: `${projectApiUrl}/refresh`,
  
};

// configuration for Axios

/**
 * Generates a dynamic Axios request configuration object.
 *
 * @param {HttpMethod} method - The HTTP method (e.g., "get", "post", "put", "delete").
 * @param {Endpoint} endpoint - The API endpoint name (e.g., "login", "registration").
 * @param {Object} data - The request payload data (used in the request body).
 * @param {Object} headers - Additional headers to be included in the request.
 * @param {Object} params - URL parameters to be included in the request.
 * @returns {AxiosRequestConfig} - The Axios request configuration object.
 */
export const generateRequestConfig = (
  method: HttpMethod,
  endpoint: Endpoint,
  data: object,
  headers: Record<string, string | null> = {},
  params: Record<string, string | number | null |undefined> = {}
): AxiosRequestConfig => {
   
  // Get the actual URL based on the endpoint name
  const url = endpointMap[endpoint];

  if (!url) {
    throw new Error(`Invalid endpoint: ${endpoint}`);
  }

  return {
    method,
    url,
    headers: {
      ...headers,
    },
    data,
    params,
  };
};

// validation
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 32;