import { LinkOrSkeletonProps, ImageLinkFooterProps } from "../../interfaces";
import i_logotop100travel from "../../assets/img/top100.travel.svg";
import i_logotop100jobs from "../../assets/img/top100.jobs.svg";

export const getFooterLinks = (
  isLoaded: boolean,
  currentYear: number,
  currentLang: string,
  t: (key: string) => string
): LinkOrSkeletonProps[] => [
  {
    isLoaded,
    href: "https://id.express",
    text: `© ${currentYear} ID.Express`,
    width: "150px",
  },
  {
    isLoaded,
    href: `/${currentLang}/terms_and_conditions/`,
    text: t("Terms and Conditions"),
    width: "175px",
  },
  {
    isLoaded,
    href: `/${currentLang}/privacy_policy/`,
    text: t("Privacy Policy"),
    width: "120px",
  },
  {
    isLoaded,
    href: `/${currentLang}/cookies/`,
    text: t("Cookies"),
    width: "75px",
  },
];

export const getImageLinks = (
  isLoaded: boolean,
  handleImageLoad: () => void
): ImageLinkFooterProps[] => [
  {
    isLoaded,
    src: i_logotop100jobs,
    alt: "top100.jobs",
    width: "84px",
    height: "20px",
    onLoad: handleImageLoad,
    className: "nav-link p-0",
  },
  {
    isLoaded,
    src: i_logotop100travel,
    alt: "top100.travel",
    width: "84px",
    height: "20px",
    onLoad: handleImageLoad,
    className: "nav-link mx-3 py-0 px-0",
  },
];
