import { MIN_PASSWORD_LENGTH } from "../../constants";

export type LoginFormFields = "email" | "password";

export interface LoginFormDataType {
  email: string;
  password: string;
}

export interface LoginFields {
  id: string;
  type: string;
  label: string;
  placeholder: string;
  iconName?: string;
  name: LoginFormFields;
  validation: any;
}

export const loginFields: Record<
LoginFormFields,
  LoginFields
> = {
  email:{
    label: "Email",
    id: "email",
    type: "email",
    name: "email",
    placeholder: "Email",
    validation: {
      required: true,
      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
    },
  },
  password:{
    label: "Password",
    id: "password",
    type: "password",
    name: "password",
    placeholder: "Password",
    iconName: "eye",
    validation: {
      required: true,
      minLength: MIN_PASSWORD_LENGTH,
    },
  },
};

export const getEmailErrorMessages = ({
  email,
}: any): Record<string, string> => ({
  required: "Email is required",
  pattern: "Invalid email address",
  server: email?.message || "Server error",
});

export const getPasswordErrorMessages = ({
  password,
}: any): Record<string, string> => ({
  required: "Password is required",
  strength: "Password must be at least 8 characters long, contain both uppercase and lowercase letters, include at least one numeric digit, and one special character.",
  minLength: "Password is too short",
  server: password?.message || "Server error",
});
