import { ValidationOptions } from "../../interfaces";

export type ResetPasswordFormFieldName = "email" ;

export interface ResetPasswordFormDataType {
  email: string;
}

export interface ResetPasswordFields {
  id: string;
  type: string;
  label: string;
  placeholder: string;
  name: ResetPasswordFormFieldName;
  validation: ValidationOptions;
}

export const resetPasswordFields: Record<
ResetPasswordFormFieldName,
ResetPasswordFields
> = {
 email:{
    label: "Email",
    id: "email",
    type: "email",
    name: "email",
    placeholder: "Email",
    validation: {
      required: true,
      pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
    },
  },
};

export const getEmailErrorMessages = ({
  email,
}: any): Record<string, string> => ({
  required: "Email is required",
  pattern: "Invalid email address",
  server: email?.message || "Server error",
});