import React from 'react';
import { Form } from 'react-bootstrap';

interface IProps {
  msg?: string;
}

const InputErrorMessage = ({ msg }: IProps) => {
  return msg ? <Form.Text className="text-danger font-weight-bold">{msg}</Form.Text> : null;
};

export default InputErrorMessage;