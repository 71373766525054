import { useState, useEffect } from "react";

const useTimer = (time: number): boolean => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, time);

    return () => clearTimeout(timer);
  }, [time]);

  return isLoaded;
};

export default useTimer;
