import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AxiosError } from 'axios';
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { LogoutFormDataType} from "./Logout.constants"; 
import { Link } from "react-router-dom";
import { generateRequestConfig } from "../../constants";
import { AuthContext } from "../../contexts/AuthContext";
import { getappInfo, handleErrorForms, acessToken, getDeviceId} from "../../utils";
import FormItem from "../../components/FormItem";
import Button from "../../components/Button";
import InputErrorMessage from "../../components/InputErrorMessage";


type LogoutState = {
  generalErrors: string | undefined;
  submitButton: boolean;
  appInfo: any;
};


const Logout: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const { AuthContextState, handleLogOut, handleLogIn, redirectToApp } = useContext(AuthContext);
  const { csrf_token, idApp  } = AuthContextState;
  
  const access_token = acessToken();

  const [state, setState] = useState<LogoutState>({
    generalErrors: undefined,
    submitButton: false,
    appInfo: null,
  });

  const {
    handleSubmit,
  } = useForm<LogoutFormDataType>();

 
  useEffect(() => { 
   redirectToApp();
    
    document.title = t("Logout");
    
    if(!appInfo)
    {
      getappInfo((data) => setState(prevState => ({ ...prevState, appInfo: data })));
    }
    

  }, [t, i18n]);

  const handleSubmitLogout: SubmitHandler<
    LogoutFormDataType
  > = async (data) => {
    const requestConfigLogout = generateRequestConfig(
      "put",
      "logout",
      {
        "device_id": getDeviceId(),
        "app_id": idApp,
        ...data,
      },
      {
        "Authorization": `Bearer ${access_token}`,
        "X-CSRF-TOKEN": csrf_token,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": currentLang,
        "Do-Not-Translate": 'en',
      }
    );

    setState(prevState => ({ ...prevState, generalErrors: undefined }));
    
    try {
      const res = await axios(requestConfigLogout);
    } catch (error : AxiosError | any) {
      const errorAction = handleErrorForms(error);
      if (errorAction) {
        const { message, status} = errorAction;
        setState(prevState => ({ ...prevState, generalErrors: message }));
        if(status==401 || status==500){
          
          if(error && error.response )
            handleLogOut(error.response.data);
        }
      }
    }
  };
  


  const handleContinue: SubmitHandler<
    LogoutFormDataType
  > = async (data) => {

    const requestConfigAutoLogIn = generateRequestConfig(
      "put",
      "oneclicklogin",
      {
        "device_id": getDeviceId(),
        "app_id": idApp,
        ...data,
      },
      {
        "Authorization": `Bearer ${access_token}`,
        "X-CSRF-TOKEN": csrf_token,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": currentLang,
        "Do-Not-Translate": 'en',
      }
    );

    setState(prevState => ({ ...prevState, generalErrors: undefined }));
    
    try {
      const res = await axios(requestConfigAutoLogIn);
      handleLogIn(res.data);

    } catch (error) {
      const errorAction = handleErrorForms(error);
      if (errorAction) {

        const { message, status} = errorAction;
        setState(prevState => ({ ...prevState, generalErrors: message }));
      
      }
    }
  };

  const {  submitButton, generalErrors, appInfo } = state;

  return (
    <>
      <h1 className="text-center auth-title-form">
        {t("Logout")}
        {appInfo && (
          <span dangerouslySetInnerHTML={{ __html: appInfo.image }} />
        )}
      </h1>
      <div className="card-body">
      <form name="AutoLogIn"  onSubmit={handleSubmit(handleContinue)}>
      <input type="hidden" name="csrf_token" value={csrf_token ? csrf_token : ''}/>
      <Button
              type="submit"
              variant="light"
              width={100}
              height={48}
              text={t("Continue")}
        />
        </form>
         <div className="p-1">
           
          </div>
        <form name="Logout"  onSubmit={handleSubmit(handleSubmitLogout)}>
        <input type="hidden" name="csrf_token" value={csrf_token ? csrf_token : ''}/>
          <FormItem>
            <Button
              type="submit"
              variant="default"
              width={100}
              height={48}
              disabled={submitButton}
              text={t("Logout")}
            />
            {generalErrors && <InputErrorMessage msg={t(generalErrors)} />}
          </FormItem>

          <div className="p-3 optext pb-5">
            {t("Continuing you agree with our")} <Link to={`/${currentLang}/terms_and_conditions/`}>{t("Terms and Conditions")}</Link> {t("and")} <Link to={`/${currentLang}/privacy_policy/`}>{t("Privacy Policy")}</Link>.
          </div>
        </form>
      </div>
    </>
  );
};

export default Logout;