import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { changePasswordFields, getpasswordErrorMessages, ChangePasswordFormDataType} from "./ChangePassword.constants";
import { Link,useNavigate } from "react-router-dom";
import { generateRequestConfig } from "../../constants";
import { AuthContext } from "../../contexts/AuthContext";
import { getappInfo, handleErrorForms } from "../../utils";
import FormItem from "../../components/FormItem";
import InputErrorMessage from "../../components/InputErrorMessage";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import Cookies from "js-cookie";
import useTimer from "../../hooks/useTimer";
import ChangePasswordSkeleton from "../Skeletons/ChangePasswordSkeleton";

const ChangePassword: React.FC = () => {
  type FormField = "password" | "passwordRepeat";

  const { AuthContextState } = useContext(AuthContext);
  const { csrf_token, idApp } = AuthContextState;
  const [jsonData, setJsonData] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();
  const isLoaded = useTimer(400);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ChangePasswordFormDataType>();
  const [generalErrorForm, setGeneralErrorForm] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    document.title = t("Reset Password");
  }, [t, i18n]);

  useEffect(() => {
    getappInfo(setJsonData);
  }, []);

  const handleSubmitChangePassword: SubmitHandler<
    ChangePasswordFormDataType
  > = async (data) => {
    const requestConfigForgot = generateRequestConfig(
      "put",
      "resetpassword",
      {
        ...data,
      },
      {
        "X-RESET-TOKEN":  `Bearer ${Cookies.get("x-reset-password-token")}`,
        "X-CSRF-TOKEN": csrf_token,
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Accept-Language": currentLang,
        "Do-Not-Translate": 'en',
      }
    );

    setGeneralErrorForm(undefined);
    
    try {
      const res = await axios(requestConfigForgot);
      if (res.status === 200 && res.data.message!=undefined) {
        Cookies.remove("x-reset-password-token");
        navigate(`/${currentLang}/login/?app_id=${idApp}`);
      }

    } catch (error) {
      const errorAction = handleErrorForms(error);
      if (errorAction) {
        const { message, field } = errorAction;
        if (field) {
          setError(field as FormField, {
            type: "server",
            message: message,
          });
        } else {
          setGeneralErrorForm(message);
        }
      }
    }
  };

  const passwordErrorMessages = getpasswordErrorMessages(errors);
  
  return (
    <>
      {!isLoaded ? <ChangePasswordSkeleton /> : (
      <>
        <h1 className="text-center auth-title-form">
          {t("Change Password")}
          {jsonData && (
            <span dangerouslySetInnerHTML={{ __html: jsonData.image }} />
          )}
        </h1>
        <div className="card-body">
          <form
            name="ChangePassword"
            onSubmit={handleSubmit(handleSubmitChangePassword)}
          >
            <FormItem
              label={t(changePasswordFields["password"].label)}
              labelFor={changePasswordFields["password"].id}
            >
              <InputField
                id={changePasswordFields["password"].id}
                type={changePasswordFields["password"].type}
                placeholder={t(changePasswordFields["password"].placeholder)}
                iconName={changePasswordFields["password"].iconName}
                {...register(
                  changePasswordFields["password"].name,
                  changePasswordFields["password"].validation
                )}
                className={
                  generalErrorForm || errors.password ? "error" : undefined
                }
              />
              {errors.password && (
                <InputErrorMessage
                  msg={t(passwordErrorMessages[errors.password.type])}
                />
              )}
            </FormItem>

            <FormItem
              label={t(changePasswordFields["passwordRepeat"].label)}
              labelFor={changePasswordFields["passwordRepeat"].id}
            >
              <InputField
                id={changePasswordFields["passwordRepeat"].id}
                type={changePasswordFields["passwordRepeat"].type}
                placeholder={t(
                  changePasswordFields["passwordRepeat"].placeholder
                )}
                iconName={changePasswordFields["passwordRepeat"].iconName}
                {...register(
                  changePasswordFields["passwordRepeat"].name,
                  changePasswordFields["passwordRepeat"].validation
                )}
                className={
                  generalErrorForm || errors.password ? "error" : undefined
                }
              />
              {errors.passwordRepeat && (
                <InputErrorMessage
                  msg={t(passwordErrorMessages[errors.passwordRepeat.type])}
                />
              )}
              {generalErrorForm && (
                <InputErrorMessage msg={t(generalErrorForm)} />
              )}
            </FormItem>

            <FormItem>
              <Button
                type="submit"
                variant="default"
                width={100}
                height={48}
                text={t("Confirm")}
              />
            </FormItem>

            <div className="p-3 optext pb-5">
              {t("Continuing you agree with our")}{" "}
              <Link to={`/${currentLang}/terms_and_conditions/`}>
                {t("Terms and Conditions")}
              </Link>{" "}
              {t("and")}{" "}
              <Link to={`/${currentLang}/privacy_policy/`}>
                {t("Privacy Policy")}
              </Link>
              .
            </div>
          </form>
        </div>
      </>
      )}
    </>
  );
};

export default ChangePassword;
