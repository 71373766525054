import React from "react";
import Router from "./Router/Router";
import "./assets/css/app.css";
import { AuthProvider } from "./contexts/AuthContext";

const App: React.FC = () => {
  return (
    <>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </>
  );
};

export default App;
