import { ValidationOptions } from "../../interfaces";
import { MIN_PASSWORD_LENGTH } from "../../constants";

export type RegistrationFormFields = "email" | "password" | "passwordRepeat";

 

export interface RegistrationFormDataType {
  email: string;
  password: string;
  passwordRepeat: string;
  agree: boolean;
}

export interface RegistrationFields {
  label: string;
  id: string;
  type: "text" | "password" | "checkbox" | "email";
  name: RegistrationFormFields;
  placeholder: string;
  validation: ValidationOptions;
  iconName?: string;
}

export const registrationFields: Record<
  RegistrationFormFields,
  RegistrationFields
> = {

  email: {
    label: "Email",
    id: "email",
    type: "email",
    name: "email",
    placeholder: "Email",
    validation: {
      required: true,
      pattern: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
    },
  },
  password:{
    label: "Password",
    id: "password",
    type: "password",
    name: "password",
    placeholder: "Password",
    iconName: "eye",
    validation: {
      required: true,
      minLength: MIN_PASSWORD_LENGTH,
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
    },
  },
  passwordRepeat:{
    label: "Re-enter Password",
    id: "passwordRepeat",
    type: "password",
    name: "passwordRepeat",
    placeholder: "Re-enter Password",
    iconName: "eye",
    validation: {
      required: true,
      minLength: MIN_PASSWORD_LENGTH,
    },
  },
};

export const getEmailErrorMessages = ({
  email,
}: any): Record<string, string> => ({
  required: "Email is required",
  pattern: "Invalid email address",
  server: email?.message || "Server error",
});

export const getPasswordErrorMessages = ({
  password,
}: any): Record<string, string> => ({
  required: "Password is required",
  pattern: "Password must be at least 8 characters long, contain both uppercase and lowercase letters, include at least one numeric digit, and one special character.",
  minLength: "Password is too short",
  validate: "Passwords don't match",
  server: password?.message || "Server error",
});

export const getPasswordRepeatErrorMessages = ({
  passwordRepeat,
}: any): Record<string, string> => ({
  required: "Password confirmation is required",
  minLength: "Password is too short",
  validate: "Passwords don't match",
  server: passwordRepeat?.message || "Server error",
});